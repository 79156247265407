import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Ajouter une signature")])]}}]),model:{value:(_vm.signatureDialog),callback:function ($$v) {_vm.signatureDialog=$$v},expression:"signatureDialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary"}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Ajouter une signature")])],1),_c(VCardText,[_c(VForm,{ref:"signatureForm"},[_c(VTextField,{attrs:{"rules":[_vm.notNullNorEmpty],"label":"Signé par"},model:{value:(_vm.signature.signe_par),callback:function ($$v) {_vm.$set(_vm.signature, "signe_par", $$v)},expression:"signature.signe_par"}}),_c(VTextField,{attrs:{"rules":[_vm.notNullNorEmpty],"label":"Page de signature","type":"number"},model:{value:(_vm.signature.signature_page),callback:function ($$v) {_vm.$set(_vm.signature, "signature_page", $$v)},expression:"signature.signature_page"}}),_c(VTextField,{attrs:{"rules":[_vm.notNullNorEmpty],"label":"Position de signature"},model:{value:(_vm.signature.signature_position),callback:function ($$v) {_vm.$set(_vm.signature, "signature_position", $$v)},expression:"signature.signature_position"}})],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.creating},on:{"click":_vm.createSignature}},[_vm._v("Ajouter")]),(_vm.creating)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }