import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-pencil")]),_vm._v(" Modifier ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"width":"600"}},[_c(VToolbar,{attrs:{"color":"primary"}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Modifier le modèle de document")])],1),_c(VCardText,[_c(VForm,[_c(VTextField,{attrs:{"label":"Nom du modèle"},model:{value:(_vm.modelDocModel.name),callback:function ($$v) {_vm.$set(_vm.modelDocModel, "name", $$v)},expression:"modelDocModel.name"}}),_c(VTextField,{attrs:{"label":"ID du document"},model:{value:(_vm.modelDocModel.model_id),callback:function ($$v) {_vm.$set(_vm.modelDocModel, "model_id", $$v)},expression:"modelDocModel.model_id"}}),_c(VTextField,{attrs:{"label":"Page de la signature"},model:{value:(_vm.modelDocModel.signature_page),callback:function ($$v) {_vm.$set(_vm.modelDocModel, "signature_page", $$v)},expression:"modelDocModel.signature_page"}}),_c(VTextField,{attrs:{"label":"Position de la signature"},model:{value:(_vm.modelDocModel.signature_position),callback:function ($$v) {_vm.$set(_vm.modelDocModel, "signature_position", $$v)},expression:"modelDocModel.signature_position"}})],1)],1),_c(VCardActions,[(_vm.loading)?_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":""}}):_vm._e(),_c(VBtn,{attrs:{"color":_vm.loading ? 'grey' : 'primary',"disabled":_vm.loading},on:{"click":_vm.patchModelDoc}},[_vm._v(" Modifier ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }