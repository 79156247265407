import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Ajouter un champ unique")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary"}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Ajouter un champ unique")])],1),_c(VCardText,[_c(VForm,{ref:"customFieldForm"},[_c(VTextField,{attrs:{"rules":[_vm.notNullNorEmpty],"label":"Nom"},model:{value:(_vm.custom_field.name),callback:function ($$v) {_vm.$set(_vm.custom_field, "name", $$v)},expression:"custom_field.name"}}),_c(VTextField,{attrs:{"rules":[_vm.notNullNorEmpty],"label":"Code"},model:{value:(_vm.custom_field.code),callback:function ($$v) {_vm.$set(_vm.custom_field, "code", $$v)},expression:"custom_field.code"}})],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.creating},on:{"click":_vm.createCustomField}},[_vm._v("Ajouter")]),(_vm.creating)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }